<template>
    <div class="statistics-cinema container">
        <breads :links="breadLinks"/>
        <PageHeader
            class="title"
            :title="$t('Statistics')"
        />
        <SelectComponent
            class="select-cities"
            :searchable="true"
            v-model="cityId"
            :options="cities"
            :placeholder="$t('City')"
            ref="getCities"
            @input="citiesChange"
            :showLabel="true"
            :clearable="true"
        />
        <div class="container-table">
            <div class="table cinema-table col-8">
                <TableSecondary
                    class="mobile-width"
                    :items="statistics"
                    :headerTitle="$t('Cinema sales')"
                    :itemsMeta="false"
                    @toggle="toggleNetwork"
                    :header-columns="columns"
                    :selectInfo="selectOptions"
                    ref="tableCinema"
                    @getItems="getData"
                    :preloader="preloaderNetwork"
                    @getSelectValue="getCinemaSelect"
                />
            </div>
            <div class="right-tables">
                <div class="table-sale">
                    <TableSecondary
                        :items="statisticsSale"
                        :headerTitle="$t('Sales statistics')"
                        :itemsMeta="false"
                        :header-columns="columnsSale"
                        :preloader="preloaderSale"
                    />
                </div>
                <div>
                    <div class="head-table">
                        {{$t('Distributor statistics')}}
                    </div>
                    <div class="table-distributors">
                        <TableSecondary
                            :items="statisticsDistributors"
                            :hiddenHead="true"
                            :itemsMeta="false"
                            :header-columns="columnsDistributors"
                            :preloader="preloaderDist"
                            :lazyLoad="lazyLoad"
                            @lazyLoadHandler="lazyLoadHandler"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="table-movies">
            <TableSecondary
                class="mobile-width"
                :items="statisticsMovies"
                :headerTitle="$t('Movie sales')"
                :itemsMeta="metaMovies"
                :header-columns="columnsMovies"
                :preloader="preloaderMovie"
                :selectInfo="selectOptionsMovie"
                @getSelectValue="getMovieSelect"
                @getItems="getMoviesStatistics"
                @getPage="getPage"
            />
        </div>
    </div>
</template>

<script>
import SelectComponent from "../components/ui/forms/SelectComponent";
import PageHeader from "../components/PageHeader";
import TableSecondary from "../components/ui/tables/TableSecondary";
import Preloader from "../components/ui/Preloader";
import Breads from "../components/Breads";


export default {
    name: "StatisticsNetwork",
    components: {
        SelectComponent,
        PageHeader,
        TableSecondary,
        Preloader,
        Breads,
    },
    data() {
        return {
            selectValue: null,
            openNetworkIds: [],
            lazyLoad: true,
            pageSaleMovies: 1,
            selectOptions: {
                defaultValue: 1,
                options: [
                    {id: 1, label: this.$t('Yesterday')},
                    {id: 2, label: this.$t('This week')},
                    {id: 3, label: this.$t('This month')},
                    {id: 4, label: this.$t('Last week')},
                    {id: 5, label: this.$t('Last month')},
                ]
            },
            selectOptionsMovie: {
                defaultValue: 1,
                options: [
                    {id: 1, label: this.$t('Yesterday')},
                    {id: 2, label: this.$t('This week')},
                    {id: 3, label: this.$t('This month')},
                    {id: 4, label: this.$t('Last week')},
                    {id: 5, label: this.$t('Last month')},
                ]
            },
            breadLinks: [
                {
                    label: this.$t('Statistics'),
                    to: { name: 'Statistics' }
                }
            ],
            testModel: 2,
            metaMovies: [],
            format: 'month',
            dateFromNetwork: '01-09-1990',
            dateToNetwork: '24-09-2021',
            dateFromMovies: '01-09-1990',
            dateToMovies: '',
            statisticsMovies: [],
            meta: [],
            type: null,
            preloaderDist: true,
            preloaderNetwork: true,
            preloaderSale: true,
            preloaderMovie: true,
            statisticsSale: [],
            statisticsDistributors: [],
            saleList: [],
            cityId: null,
            columnsMovies: [
                {
                    id: 1,
                    label: this.$t('Movie'),
                    sort: false,
                    attribute: "filmName",
                },
                {
                    id: 2,
                    label: this.$t('Distributors'),
                    sort: false,
                    attribute: "distName",
                },
                {
                    id: 3,
                    label: this.$t('Tickets'),
                    sort: false,
                    attribute: "tickets_count",
                },
                {
                    id: 4,
                    label: this.$t('Sum'),
                    sort: false,
                    attribute: "sum",
                },
            ],
            columnsDistributors: [
                {
                    id: 1,
                    label: this.$t('Distributors'),
                    sort: false,
                    attribute: "distName",
                },
                {
                    id: 2,
                    label: this.$t('Movies '),
                    sort: false,
                    attribute: "filmCount",
                },
            ],
            columnsSale: [
                {
                    id: 1,
                    label: this.$t('Period'),
                    sort: false,
                    attribute: "period",
                },
                {
                    id: 2,
                    label: this.$t('Tickets'),
                    sort: false,
                    attribute: "tickets",
                },
                {
                    id: 3,
                    label: this.$t('Sum'),
                    sort: false,
                    attribute: "sum",
                },
            ],
            columns: [
                {
                    id: 1,
                    label: this.$t('Cinema'),
                    sort: false,
                    attribute: "name",
                },
                {
                    id: 2,
                    label: this.$t('Tickets'),
                    sort: false,
                    attribute: "count",
                },
                {
                    id: 3,
                    label: this.$t('Sum'),
                    sort: false,
                    attribute: "sum",
                },
            ],
            networkList: [],
            page: 1,
            lastDistributorPage: null,
            distributorPage: 1,
            data: [],
            maxPageDistributor: null,
            statistics: [],
            scrolledToBottom: false,
            cities: [],
            getLastPage: true
        }
    },
    watch:{
        lastDistributorPage(){
            if (this.getLastPage){
                this.getLastPage = false
                this.lazyLoadHandler()
            }
        }
    },
    methods: {
        toggleNetwork(id) {
            let index = this.openNetworkIds.indexOf(id);
            if (index !== -1) {
                this.openNetworkIds.splice(index, 1);
            } else {
                this.openNetworkIds.push(+id);
            }
            this.sortData();
        },
        lazyLoadHandler(){
            this.lazyLoad = false
            if (this.distributorPage <= this.lastDistributorPage){
                ++this.distributorPage
                this.getDistributorList()
                setTimeout(() => this.lazyLoad = true, 2000)
            }
        },
        async getData() {
            this.preloaderNetwork = true
            this.page = this.$route.query.page
            let resp = await this.api.statistics.getNetworkStatistics(this.format, this.dateFromNetwork, this.dateToNetwork, this.page, this.cityId)
            this.meta = resp
            this.data = resp.data
            this.sortData()
            this.preloaderNetwork = false
        },
        getSelectValue(value) {
            let dateNow = new Date()
            let dateFrom;
            let dateTo;

            if (value == 1){
                dateFrom = this.helper.getYesterdayDate(dateNow)
                dateTo = this.helper.getYesterdayDate(dateNow)

            } else if (value == 2){
                dateFrom = this.helper.getCurrentWeek(dateNow)
                dateTo = this.helper.getDate(dateNow)

            } else if (value == 3){
                dateFrom = this.helper.getCurrentMonth(dateNow)
                dateTo = this.helper.getDate(dateNow)

            } else if (value == 4){
                dateFrom = this.helper.getStartPreviousWeek(dateNow)
                dateTo = this.helper.getEndPreviousWeek(dateNow)

            } else if (value == 5){
                dateFrom = this.helper.getStartPreviousMonth(dateNow)
                dateTo = this.helper.getEndPreviousMonth(dateNow)
            }

            return {
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
        getCinemaSelect(value){
            this.selectOptions.defaultValue = value

            let newTime = this.getSelectValue(value)

            this.dateFromNetwork = newTime.dateFrom
            this.dateToNetwork = newTime.dateTo
            this.getData()
        },
        getMovieSelect(value){
            this.selectOptionsMovie.defaultValue = value


            let newTime = this.getSelectValue(value)
            this.dateFromMovies = newTime.dateFrom
            this.dateToMovies = newTime.dateTo

            this.getMoviesStatistics()
        },
        citiesChange(v){
            this.distributorPage = 1
            this.statisticsDistributors = []
            this.getData()
            this.getSaleList()
            this.getDistributorList()
            this.getMoviesStatistics()
        },
        sortData() {
            let data = [];
            this.data.forEach(network => {
                data.push({
                    haveParent: true,
                    name: network.name,
                    id: network.id,
                    sum: network.stats.tickets_amount,
                    count: network.stats.tickets_count,
                    is_open: this.openNetworkIds.includes(network.id),
                    group: true,
                    cinemasCount: network.cinemas ? network.cinemas.length : 0
                });

                if (!network.cinemas) {
                    return false;
                }

                network.cinemas.forEach(cinema => {
                    data.push({
                        haveParent: true,
                        name: cinema.name,
                        id: cinema.id,
                        sum: cinema.stats.tickets_amount,
                        parent_id: network.id,
                        show: this.openNetworkIds.includes(network.id),
                        count: cinema.stats.tickets_count
                    });
                });
            });
            this.statistics = data;
        },
        async getSaleList() {
            this.preloaderSale = true
            this.statisticsSale = []
            let resp = await this.api.statistics.getSalesStatistics(this.type, this.cityId, null, 1)
            this.saleList = resp
            for (let [key, value] of Object.entries(this.saleList)) {
                if (key === 'yesterday') {
                    key = this.$t('Yesterday')
                } else if (key === 'current_week') {
                    key = this.$t('This week')
                } else if (key === 'previous_week') {
                    key = this.$t('Last week')
                } else if (key === 'current_month') {
                    key = this.$t('This month')
                } else if (key === 'previous_month') {
                    key = this.$t('Last month')
                }
                this.statisticsSale.push({
                    period: key,
                    tickets: value.tickets_count,
                    sum: value.tickets_amount
                })

            }
            this.preloaderSale = false
        },
        async getDistributorList() {
            // this.preloaderDist = true
            // this.statisticsDistributors = []
            let resp = await this.api.statistics.getDistributorsStatistic(this.cityId, this.distributorPage)
            this.lastDistributorPage = resp.last_page
            resp.data.forEach(dist => {
                this.statisticsDistributors.push({
                    filmCount: dist.movies_count,
                    distName: dist.name,
                })
            })
            this.preloaderDist = false
        },
        async getCities(data = null) {
            let cities = await this.ApiService.city.getCities(data)
            cities.map(elem => {
                elem.label = elem.full_name
                return elem
            })
            this.cities = cities;
            if (this.$refs.getCities) {
                this.$refs.getCities.$forceUpdate();
            }
        },
        getPage(p){
            this.pageSaleMovies = p
        },
        async getMoviesStatistics(){

            let resp = await this.ApiService.statistics.getMoviesStatistics(this.format, this.dateFromMovies, this.dateToMovies, this.cityId, this.pageSaleMovies)
            this.metaMovies = resp
            this.statisticsMovies = []
            resp.data.forEach(movie => {
                this.statisticsMovies.push({
                    filmName: movie.name,
                    distName: movie.distributor.name,
                    tickets_count: movie.stats.tickets_count,
                    sum: movie.stats.tickets_amount
                })
            })
            this.preloaderMovie = false
        }
    },
    created() {
        this.dateToMovies = this.helper.getYesterdayDate(new Date())
        this.dateFromMovies = this.helper.getYesterdayDate(new Date())
        this.dateToNetwork = this.helper.getYesterdayDate(new Date())
        this.dateFromNetwork = this.helper.getYesterdayDate(new Date())

        this.getData()
        this.getSaleList()
        this.getDistributorList()
        this.getCities({ cities: 1 })
        this.getMoviesStatistics()
    },

}
</script>

<style scoped lang="scss">

.statistics-cinema {
    min-height: 80vh;
    position: relative;

    .mobile-width{
        @media (max-width: 768px) {
            min-width: 700px;
        }
    }

    .table-sale{
        overflow-y: hidden;
    }

    .select-cities{
        width: 263px;
        position: absolute;
        right: 16px;
        top: 25px;
        @media (max-width: 552px) {
            position: relative;
            right: -20px;
            top: -12px;
        }
    }

    .right-tables {
        width: 32%;
        height: 885px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 20px;
        }

        .table-sale {
            max-height: 435px;
            height: 435px;
            overflow-x: auto;
            overflow-y: hidden;
        }

        .table-distributors {
            overflow-x: hidden;
            max-height: 435px;
            height: 400px;
        }
    }

    .cinema-table{
        @media (max-width: 768px){
            overflow-x: scroll;
            white-space: nowrap;
        }
    }
    .container-table {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        @media (max-width: 768px) {
            flex-direction: column;

        }
        .head-table{
            height: 50px;
            background-color: $color_lightpink;
            font-size: 20px;
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;
            padding-left: 15px;
        }
        .table {
            width: 65%;
            margin-bottom: 0;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}

::v-deep {
    .secondary-table {
        //min-height: 885px;
        min-height: 100%;

        .table {
            .table__labelTr {
                th:first-child {
                    width: 82% !important;
                }

                th:last-child {
                    padding-right: 30px;
                }
            }
            .parent td div{
                color: black;
            }
        }
    }

    .title {
        margin-top: 13px;
    }

    .table-movies{
        @media (max-width: 768px) {
            overflow-x: scroll;
            white-space: nowrap;
        }
        .secondary-table{
            .table .table__labelTr th:first-child{
                width: 61%!important;
            }
        }
    }

    .pagination{
        .button{
            background-color: transparent;
            box-shadow: none;
            &:hover{
                box-shadow: 0 4px 10px 0 rgb(53 0 212 / 25%);
            }
        }
    }

    .select-element{
        display: flex;
        align-items: center;
        .select-element__label{
            margin-bottom: 0;
            position: relative;
            right: 15px;
        }
    }
    .select-cities{
        width: 303px!important;
        .vs__dropdown-menu{
            overflow-x: hidden;
            .vs__dropdown-option{
                white-space: inherit;
            }
        }
    }
    @media (max-width: 768px) {
        .secondary-table.adaptive--md{
            min-width: auto;
        }
    }
    .v-select{
        height: 40px;
        .vs__search{
            cursor: pointer;
        }
    }
    .form-control{
        padding: 2.5px 7px;
    }
}

::v-deep{
    .select-cities{
        .v-select{
            background-color: #F4F7FF;
            border-radius: 2px;
            border: solid 1px #cad9f3;
        }
    }
}

</style>
